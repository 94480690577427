import React from 'react';
import LogoBox from './LogoBox';

const styles = {
  "backgroundColor": "#068ae1",
  "backgroundImage": "url(/images/alarm-background-thin.jpg)",
  "backgroundSize": "auto 100%",
  "backgroundPosition": "center",
  "backgroundRepeat": "no-repeat",
}

const NormalHeader = () => (
  <section id="NormalHeader" style={styles}>
    <div className="container text-white mx-auto pt-4 pb-5 lg:pt-8 lg:pb-9 font-roboto">
      <LogoBox />

      <div className="hidden sm:block text-xl pl-1 mt-12 tracking-wide w-1/2 font-light leading-relaxed">
        We use all available methods to contact expiring website, including
        <span className="text-orange-700 font-normal"> phone calls </span>
        and
        <span className="text-orange-700 font-normal"> contact forms</span>
      </div>
    </div>
  </section>
)

export default NormalHeader
