import React from 'react';

const Link = ({ href = '', className = '', children }) => {
  return (
    <a
      href={`${href}`}
      className={`text-blue-700 hover:text-blue-400 ${className}`}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
}

export default Link;
