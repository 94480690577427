import React from 'react';
import { Helmet } from "react-helmet";
import Footer from './Footer';
import NormalHeader from './NormalHeader';
import HeroHeader from './HeroHeader'

const Layout = ({ header, children }) => {
  return (
    <>
      <Helmet>
        <title>SSL Pulse</title>
        <link
          href="/images/favicon.ico"
          rel="shortcut icon"
          type="image/x-icon"
        />
      </Helmet>

      {header === "normal" && <NormalHeader />}
      {header === "hero" && <HeroHeader />}

      <main className="bg-white">{children}</main>

      <Footer />
    </>
  );
};

export default Layout;
