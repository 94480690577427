import React from 'react';

const LogoBox = () => (
  <div className="ml-2 sm:ml-0">
    <h1 className="text-7xl font-bold">
      <a href="/">
        <span className="text-orange-700">SSL</span> PULSE
      </a>
    </h1>

    <div className="text-xl pl-1 tracking-widest">ssl expiration alerts by humans</div>
  </div>
)

export default LogoBox
