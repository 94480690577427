import React from 'react';
import LogoBox from './LogoBox';

// import Link from '../../components/Link';

const styles = {
  "backgroundColor": "#068ae1",
  "backgroundImage": "url(/images/alarm-background.jpg)",
  "backgroundSize": "auto 100%",
  "backgroundPosition": "center",
  "backgroundRepeat": "no-repeat",
}

const HeroHeader = () => (
  <section id="HeroHeader" style={styles}>
    <div className="container text-white mx-auto pt-20 pb-14 lg:pt-28 lg:pb-20 font-roboto">
      <LogoBox />

      <div className="hidden sm:block text-2xl pl-1 mt-12 tracking-wide w-1/2 font-light leading-relaxed">
        We use all available methods to contact expiring website, including
        <span className="text-orange-700 font-normal"> phone calls </span>
        and
        <span className="text-orange-700 font-normal"> contact forms</span>
      </div>

      {/* TODO: uncomment */}
      {/* <div className="mt-8 pl-1">
        <button className="px-6 py-3 bg-white text-blue-400 font-bold text-lg rounded">Get Protected</button>
      </div> */}
    </div>
  </section>
)

export default HeroHeader
