import React from 'react';

const Footer = () => (
  <footer className="py-10 bg-black text-white">
    <div className="container sm:mx-auto px-8 lg:px-16">
      <ul className="flex flex-row justify-between lg:items-center space-y-0 text-gray-400" >
        <li>
          <strong className="uppercase" >
            &copy; SSL Pulse
          </strong>
        </li>
        <li className="hidden lg:block">
          {/* <p className="font-bold">Company registration pending</p> */}
        </li>
        <li>
          <a
            className="font-bold"
            href="mailto:hello@sslpulse.org"
            target="_blank"
            rel="noreferrer"
          >
            Contact us: <span className="text-gray-300">hello@sslpulse.org</span>
          </a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
